import { useState, useEffect, useRef, Fragment } from "react";
import React from "react";
import { Formik, Field } from "formik";
import Payment from "./Payment";
import NavigationMenu from "../NavigationMenu";
import Upload from "../Upload/Upload";
import Cart from "./Cart";
import axios from "axios";
import { useGlobalState } from "../GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { getSettingsId } from "../ProductSettings/Helper";
import { toast } from "react-toastify";
import SelectCountry from "./SelectCountry";
import "react-toastify/dist/ReactToastify.min.css";
import { useMediaQuery } from "react-responsive";

export default function Checkout(props) {
    const { loggedInUser, generalSettings } = useGlobalState();
    const [countries, setCountries] = useState([]);
    const [showShippingCosts, setShowShippingCosts] = useState(true);
    const [quotationApiData, setQuotationApiData] = useState({});
    const [textData, setTextData] = useState();
    const [userData, setUserData] = useState({});
    const [doRender, setDoRender] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [paymentChoice, setPaymentChoice] = useState({});
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [originalDeliveryOptions, setOriginalDeliveryOptions] = useState([]);
    const [shippingInfo, setShippingInfo] = useState(null);
    const [shippingCountry, setShippingCountry] = useState(null);
    const [loadingShippingPrices, setLoadingShippingPrices] = useState(true);
    const [deliveryPrice, setDeliveryPrice] = useState(null);
    const [deliveryPriceError, setDeliveryPriceError] = useState(false);
    const [uploadedAttachments, setUploadedAttachments] = useState([]);
    const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const deliveryOption = useRef(null);
    const hasCompany = useRef(false);
    const [stateErrors, setStateErrors] = useState([]);
    const { productSettings, language } = useGlobalState();
    const requiredFields = [
        "firstname",
        "lastname",
        "email",
        "company",
        "street",
        "housenr",
        "zip",
        "city",
        "country",
        "terms_and_conditions_read",
        "checklist_read",
    ];

    // MediaQuery variables
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1150px)" });
    const responsiveClass = "rsp-";

    useEffect(() => {
        setDeliveryPrice(null);
        setDeliveryPriceError(false);
    }, [shippingCountry]);

    useEffect(() => {
        if (typeof deliveryOptions.set_prices !== "undefined") {
            return;
        }

        if (
            typeof shippingInfo !== "undefined" &&
            shippingInfo !== null &&
            shippingInfo !== 0 &&
            typeof shippingInfo.shipping_costs !== "undefined" &&
            shippingInfo.shipping_costs !== null &&
            originalDeliveryOptions.length > 0
        ) {
            let newOptions = originalDeliveryOptions.map((option) => {
                if (option.youropps_name === "kdzpriority") {
                    option.price = shippingInfo.shipping_costs.priority_goods_euro_price;
                } else if (option.youropps_name === "kdzexpress1200") {
                    option.price = shippingInfo.shipping_costs.priority_1200_euro_price;
                } else if (option.youropps_name === "kdzexpress900") {
                    option.price = shippingInfo.shipping_costs.priority_900_euro_price;
                } else if (option.youropps_name === "kdzroad") {
                    option.price = shippingInfo.shipping_costs.road_goods_euro_price;
                } else if (option.youropps_name === "pickup") {
                    option.price = 0;
                } else {
                    option.price = null;
                }
                return option;
            });

            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get("id");
            if (typeof id !== "undefined" && id !== null) {
                if (
                    typeof quotationApiData[0] !== "undefined" &&
                    quotationApiData[0].order.delivery_option_id !== null
                ) {
                    if (
                        quotationApiData[0].order.custom_delivery_country === shippingCountry &&
                        shippingCountry !== null
                    ) {
                        deliveryOption.current = quotationApiData[0].order.delivery_option_id;
                        newOptions = newOptions.filter(
                            (option) => option.id === parseInt(quotationApiData[0].order.delivery_option_id)
                        );
                        // Change the option's price
                        newOptions[0].price = parseFloat(quotationApiData[0].order.delivery_price);
                    }
                }
            }
            newOptions.set_prices = true;

            setLoadingShippingPrices(false);
            setDeliveryOptions(newOptions);
        } else if (
            typeof shippingInfo !== "undefined" &&
            shippingInfo !== null &&
            shippingInfo.shipping_costs === null
        ) {
            // Could not fetch prices
            console.log("Could not fetch prices");
            setDeliveryPriceError(true);
        }
    }, [deliveryOptions, shippingInfo]);

    useEffect(() => {
        if (
            typeof shippingInfo !== "undefined" &&
            shippingInfo !== null &&
            typeof shippingInfo.shipping_costs !== "undefined" &&
            shippingInfo.shipping_costs !== null &&
            shippingInfo !== 0 &&
            originalDeliveryOptions.length > 0
        ) {
            let newOptions = originalDeliveryOptions.map((option) => {
                if (option.youropps_name === "kdzpriority") {
                    option.price = shippingInfo.shipping_costs.priority_goods_euro_price;
                } else if (option.youropps_name === "kdzexpress1200") {
                    option.price = shippingInfo.shipping_costs.priority_1200_euro_price;
                } else if (option.youropps_name === "kdzexpress900") {
                    option.price = shippingInfo.shipping_costs.priority_900_euro_price;
                } else if (option.youropps_name === "kdzroad") {
                    option.price = shippingInfo.shipping_costs.road_goods_euro_price;
                } else if (option.youropps_name === "pickup") {
                    option.price = 0;
                } else {
                    option.price = null;
                }
                return option;
            });

            let urlParams = new URLSearchParams(window.location.search);
            let id = urlParams.get("id");
            if (typeof id !== "undefined" && id !== null) {
                if (
                    typeof quotationApiData[0] !== "undefined" &&
                    quotationApiData[0].order.delivery_option_id !== null
                ) {
                    if (
                        quotationApiData[0].order.custom_delivery_country === shippingCountry &&
                        shippingCountry !== null
                    ) {
                        deliveryOption.current = quotationApiData[0].order.delivery_option_id;
                        newOptions = newOptions.filter(
                            (option) => option.id === parseInt(quotationApiData[0].order.delivery_option_id)
                        );
                        // Change the option's price
                        newOptions[0].price = parseFloat(quotationApiData[0].order.delivery_price);
                    }
                }
            }
            newOptions.set_prices = true;

            setLoadingShippingPrices(false);
            setDeliveryOptions(newOptions);
        } else if (
            typeof shippingInfo !== "undefined" &&
            shippingInfo !== null &&
            shippingInfo.shipping_costs === null
        ) {
            // Could not fetch prices
            console.log("Could not fetch prices");
            setDeliveryPriceError(true);
        }
    }, [shippingInfo]);

    useEffect(() => {
        setInitialValues({
            ...{
                firstname: loggedInUser.user.first_name === null ? "" : loggedInUser.user.first_name,
                lastname: loggedInUser.user.last_name === null ? "" : loggedInUser.user.last_name,
                phone: loggedInUser.user.phone === null ? "" : loggedInUser.user.phone,
                mobile_phone: loggedInUser.user.mobile_phone === null ? "" : loggedInUser.user.mobile_phone,
                email: loggedInUser.user.email === null ? "" : loggedInUser.user.email,
                differentAddress: "0",
                diff_firstname: "",
                diff_lastname: "",
                diff_phone: "",
                diff_mobile_phone: "",
                diff_email: "",
                diff_company: "",
                diff_street: "",
                diff_housenr: "",
                diff_zip: "",
                diff_place: "",
                diff_country: "Netherlands",
                diff_additional_address_information: "",
                terms_and_conditions_read: false,
                checklist_read: false,
            },
            ...getInitCompanyValues(),
        });
    }, [loggedInUser]);

    useEffect(() => {
        if (typeof quotationApiData[0] !== "undefined") {
            setDoRender(true);
        }
    }, [quotationApiData]);

    const getGeneralSetting = (setting) => {
        if (typeof generalSettings[setting] === "undefined" || generalSettings[setting] === null) {
            return "";
        } else {
            return window.location.origin + "/storage/" + generalSettings[setting];
        }
    };

    useEffect(() => {
        setTextData(props.textData);
        if (loggedInUser !== null) {
            setUserData(loggedInUser);

            if (typeof loggedInUser.company !== "undefined" && loggedInUser.company !== null) {
                setShippingCountry(loggedInUser.company.country);
            } else if (typeof loggedInUser.country !== "undefined" && loggedInUser.country !== null) {
                setShippingCountry(loggedInUser.country);
            }
        }
        // Check if id is supplied in the url and get the quotation data.
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");
        if (id !== null && typeof id !== "undefined") {
            axios
                .get(window.location.origin + `/api/quotation?quotation_id=${id}&user_id=${loggedInUser.user.id}`)
                .then((response) => {
                    // Change orders key to models
                    let a = {
                        data: response.data.order.models,
                        products: response.data.order.products,
                        order: response.data.order,
                    };
                    console.log(a);
                    setQuotationApiData([a]);
                    // fill id reference
                    if (typeof response.data.order.reference !== "undefined" && response.data.order.reference !== null)
                        waitForElm("#reference").then((elm) => {
                            elm.value = response.data.order.reference;
                        });
                });
        } else {
            setDoRender(true);
        }

        getDeliveryOptions();
    }, []);

    const getDeliveryOptions = () => {
        axios.get(window.location.origin + "/api/delivery-options").then((response) => {
            setDeliveryOptions(response.data.deliveryOptions);
            setOriginalDeliveryOptions(response.data.deliveryOptions);
        });
    };

    useEffect(() => {
        // Get countries
        const fetchCountries = async () => {
            try {
                const response = await axios.get(window.location.origin + "/api/countries");
                const error = response.data.error;
                if (!error) {
                    return new Promise((resolve) => {
                        resolve(response.data.countries);
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountries().then((result) => {
            let countries = [];
            result.forEach((country) => {
                countries.push({
                    value: country,
                    label: country,
                });
            });
            setCountries(countries);
        });
    }, []);

    const handleValidationErrors = (errors) => {
        if (hasSubmittedForm === true) {
            for (let err in errors) {
                toast.error(textData["checkout/form"]["form_errors"][errors[err]]);
            }
            setHasSubmittedForm(false);
        }
    };

    const getInitCompanyValues = () => {
        if (loggedInUser.company === null || typeof loggedInUser.company === "undefined") {
            let company = {
                company:
                    typeof loggedInUser?.user?.company === "undefined" || loggedInUser?.user?.company === null
                        ? "-"
                        : loggedInUser?.user?.company,
                street: typeof loggedInUser?.user?.street === "undefined" ? "" : loggedInUser?.user.street,
                housenr: typeof loggedInUser?.user?.streetnumber === "undefined" ? "" : loggedInUser?.user.streetnumber,
                zip: typeof loggedInUser?.user?.zipcode === "undefined" ? "" : loggedInUser?.user.zipcode,
                city: typeof loggedInUser?.user?.city === "undefined" ? "" : loggedInUser?.user.city,
                country: typeof loggedInUser?.user?.country === "undefined" ? "" : loggedInUser?.user.country,
                additional_address_information:
                    typeof loggedInUser?.user?.additional_address_information === "undefined"
                        ? ""
                        : loggedInUser?.user.additional_address_information,
                diff_additional_address_information:
                    typeof loggedInUser?.user?.additional_address_information === "undefined"
                        ? ""
                        : loggedInUser?.user.additional_address_information,
            };
            return company;
        } else {
            let company = {
                company: typeof loggedInUser?.company?.name === "undefined" ? "" : loggedInUser?.company.name,
                street: typeof loggedInUser?.company?.address === "undefined" ? "" : loggedInUser?.company.address,
                housenr:
                    typeof loggedInUser?.company?.house_number === "undefined"
                        ? ""
                        : loggedInUser?.company.house_number,
                zip: typeof loggedInUser?.company?.zip === "undefined" ? "" : loggedInUser?.company.zip,
                city: typeof loggedInUser?.company?.city === "undefined" ? "" : loggedInUser?.company.city,
                country: typeof loggedInUser?.company?.country === "undefined" ? "" : loggedInUser?.company.country,
                additional_address_information:
                    typeof loggedInUser?.company?.additional_address_information === "undefined"
                        ? ""
                        : loggedInUser?.company.additional_address_information,
                diff_additional_address_information:
                    typeof loggedInUser?.company?.additional_address_information === "undefined"
                        ? ""
                        : loggedInUser?.company.additional_address_information,
            };
            hasCompany.current = true;
            return company;
        }
    };

    const resetStyling = () => {
        // remove red border from all fields
        let fields = document.querySelectorAll(".border-error");
        for (let i = 0; i < fields.length; i++) {
            fields[i].classList.remove("border-error");
        }
    };

    // Makes a input field red (if an error occurs for example)
    const makeFieldRed = (fieldName) => {
        let field;
        switch (fieldName) {
            case "payment_option":
                field = document.getElementsByClassName("payment")[0];
                field.classList.add("border-error");
                break;
            case "delivery_option":
                field = document.getElementById("delivery");
                field.classList.add("border-error");
                break;
            case "checklist_read":
                field = document.getElementsByClassName("disclaimer")[0].children[2];
                field.classList.add("border-error");
                break;
            case "terms_and_conditions_read":
                field = document.getElementsByClassName("disclaimer")[0].children[1];
                field.classList.add("border-error");
                break;
            default:
                try {
                    field = document.getElementsByName(fieldName)[0];
                    field.classList.add("border-error");
                } catch (e) {}
                break;
        }
    };

    // Waits untill an element exists in the DOM and then returns it
    function waitForElm(selector) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    }

    const handleFormValidation = (values) => {
        if (hasSubmittedForm === true) {
            resetStyling();
        }

        let errors = [];
        // Check if required fields are filled in
        for (let value in values) {
            if (!value.startsWith("diff")) {
                if (requiredFields.includes(value) && (values[value] === "" || values[value] === null)) {
                    errors.push(value);
                } else {
                    const field = document.querySelector("input[name=" + value + "]");
                    field.classList.contains("border-error") && field.classList.remove("border-error");
                }
            }
        }

        // Check if different address is checked
        let differentAddress = document.getElementById("differentAddress");
        if (differentAddress.checked) {
            for (let value in values) {
                if (value.startsWith("diff")) {
                    if (
                        values[value] === "" &&
                        value !== "diff_additional_address_information" &&
                        value !== "diff_company" &&
                        value !== "diff_phone" &&
                        value !== "diff_mobile_phone"
                    ) {
                        errors.push(value);
                    }
                }
            }
        }

        // Check if payment choice is made
        if (Object.keys(paymentChoice).includes("service")) {
            let found = false;
            for (let method in paymentMethods) {
                if (paymentMethods[method].description === paymentChoice.service) {
                    found = true;
                    if (
                        paymentMethods[method].issuers !== null &&
                        (paymentChoice.issuer === "" || paymentChoice === null)
                    ) {
                        errors.push("payment_option");
                    }
                }
            }
            if (!found) {
                errors.push("payment_option");
            }
        } else {
            errors.push("payment_option");
        }

        // Check if emails are correct format
        if (values.email !== "" && !validateEmail(values.email)) {
            errors.push("email");
        }

        if (values.diff_email !== "" && !validateEmail(values.diff_email) && differentAddress.checked) {
            errors.push("diff_email");
        }

        // Check if delivery choice is made
        if (deliveryOption.current === null) {
            errors.push("delivery_option");
        }

        if (!values.terms_and_conditions_read) {
            errors.push("terms_and_conditions_read");
        }

        if (!values.checklist_read) {
            errors.push("checklist_read");
        }

        errors.forEach((err) => {
            makeFieldRed(err);
        });

        setStateErrors(errors);
        return errors;
    };

    const handleFormSubmit = (values) => {
        // get models from localstorage
        let models = null;
        if (
            quotationApiData !== "undefined" &&
            quotationApiData !== null &&
            Object.keys(quotationApiData).length !== 0
        ) {
            models = quotationApiData;
        } else {
            models = JSON.parse(localStorage.getItem("modelsData"));
        }

        let modelPayload = [];
        if (typeof models !== "undefined" && models !== null && models.length > 0) {
            for (let model in models[0].data) {
                const current = models[0].data[model];
                let obj = {
                    model_id: current.id,
                    settings_id: getSettingsId(current.settings, productSettings),
                    amount: current.amount,
                };

                if (current.settings.custom) {
                    obj.practitioner = current.settings.practitioner;
                    obj.case = current.settings.case;
                }
                modelPayload.push(obj);
            }
        } else {
            models = [];
        }

        // Check if different address is checked
        if (document.getElementById("differentAddress").checked) {
            values.street = values.diff_street;
            values.housenr = values.diff_housenr;
            values.zip = values.diff_zip;
            values.city = values.diff_place;
            values.country = values.diff_country;
            values.additional_address_information = values.diff_additional_address_information;
        }

        // Check if referenc is filled in (field not in this component so we get it by id)
        //id = reference
        let reference = document.getElementById("reference");

        let discountCodes = [];
        discounts.forEach((discount) => {
            discountCodes.push(discount.code);
        });

        // Check if this order is made from an existing quotation
        // get url param id
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");
        let quotation_id = null;
        if (id !== null && typeof id !== "undefined") {
            quotation_id = id;
        }

        // Create order
        axios
            .post(window.location.origin + "/api/order", {
                reference: reference.value !== "" ? reference.value : "",
                user_id: loggedInUser.user.id,
                user_details: {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    phone: values.phone,
                    mobile_phone: values.mobile_phone,
                    email: values.email,
                },
                diff_user_details: {
                    firstname: values.diff_firstname,
                    lastname: values.diff_lastname,
                    phone: values.diff_phone,
                    mobile_phone: values.diff_mobile_phone,
                    email: values.diff_email,
                    company: values.diff_company,
                },
                delivery_details: {
                    street: values.street,
                    streetnumber: values.housenr,
                    city: values.city,
                    zipcode: values.zip,
                    country: values.country,
                    additional_address_information: values.additional_address_information,
                    delivery_option_id: deliveryOption.current,
                },
                models: modelPayload,
                products: JSON.parse(localStorage.getItem("productsData")),
                attachments: uploadedAttachments,
                discount_codes: discountCodes,
                payment_method:
                    loggedInUser.company !== null && loggedInUser.company.allow_pay_by_wire == true
                        ? "pay_by_wire"
                        : paymentChoice.service.toLowerCase().replaceAll(" ", ""),
                payment_issuer: paymentChoice.issuer,
                quotation_id: quotation_id,
            })
            .then((response) => {
                toast.success("Order created!");
                localStorage.removeItem("modelsData");
                localStorage.removeItem("productsData");

                // Check if checkoutUrl exists
                if (typeof response.data.checkoutUrl === "undefined") {
                    setUploadedAttachments([]);
                    props.navigateToPageName("account_orders");
                } else {
                    window.location.href = response.data.checkoutUrl;
                }
            })
            .catch((error) => {
                toast.error("Something went wrong while creating your order.");
            });
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        doRender && (
            <div className="root" style={{ overflowY: "auto", height: "auto", gap: "20px" }}>
                {!isTabletOrMobile ? (
                    <>
                        <div className="screen-left">
                            <Cart
                                textData={textData}
                                mandatoryProductOptions={props.mandatoryProductOptions}
                                showShippingCosts={showShippingCosts}
                                modelsData={quotationApiData}
                                navigateToPageName={props.navigateToPageName}
                                setShippingInfo={setShippingInfo}
                                shippingCountry={shippingCountry}
                                deliveryPrice={deliveryPrice}
                                setDiscounts={setDiscounts}
                            />
                        </div>
                        <div className="screen-right">
                            <div className="header">
                                <h1>{!!textData && textData["checkout/form"]["title"]}</h1>
                                <NavigationMenu
                                    textData={!!textData && textData}
                                    authenticated={props.authenticated}
                                    navigateToPageName={props.navigateToPageName}
                                />
                            </div>
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                initialValues={initialValues}
                                validate={(values) => {
                                    if (
                                        loggedInUser.company !== null &&
                                        (typeof loggedInUser.company.vat_number === "undefined" ||
                                            loggedInUser.company.vat_number === null ||
                                            loggedInUser.company.vat_number === "" ||
                                            loggedInUser.company.vat_number === "-") &&
                                        loggedInUser.company.country !== "Netherlands"
                                    ) {
                                        toast.error(textData["checkout/form"]["form_errors"]["vat_number"]);
                                        setStateErrors(["vat_number"]);
                                        return [textData["checkout/form"]["form_errors"]["vat_number"]];
                                    } else {
                                        return handleFormValidation(values);
                                    }
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        handleFormSubmit(values);
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit} id="checkout-form">
                                        {errors.length > 0 && stateErrors.length > 0 && handleValidationErrors(errors)}
                                        <div className="checkout">
                                            <h2>{!!textData && textData["checkout/form"]["information"]["title"]}</h2>
                                            <div className="details">
                                                <div className="form-group">
                                                    {Object.entries(values).map(([fieldName, fieldLabel], index) => {
                                                        if (
                                                            fieldName === "terms_and_conditions_read" ||
                                                            fieldName === "checklist_read"
                                                        ) {
                                                            return "";
                                                        }

                                                        if (fieldName === "country") {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <SelectCountry
                                                                        textData={textData}
                                                                        countries={countries}
                                                                        setFieldValue={setFieldValue}
                                                                        setShippingCountry={setShippingCountry}
                                                                        setLoadingShippingPrices={
                                                                            setLoadingShippingPrices
                                                                        }
                                                                        initialValues={initialValues}
                                                                        id="country"
                                                                    />
                                                                </React.Fragment>
                                                            );
                                                        } else if (
                                                            loggedInUser.company === null &&
                                                            fieldName === "company"
                                                        ) {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="formInputField">
                                                                        <input
                                                                            type="text"
                                                                            id={`${fieldName}`}
                                                                            name={`${fieldName}`}
                                                                            value={"-"}
                                                                            onChange={handleChange}
                                                                            hidden={true}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        } else if (!fieldName.startsWith("diff")) {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="formInputField">
                                                                        <label htmlFor={`${fieldName}`}>
                                                                            {
                                                                                textData["checkout/form"][
                                                                                    "form_details"
                                                                                ][fieldName]
                                                                            }
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id={`${fieldName}`}
                                                                            name={`${fieldName}`}
                                                                            value={values[fieldName] ?? ""}
                                                                            onChange={handleChange}
                                                                            readOnly={
                                                                                fieldName === "company"
                                                                                    ? "readonly"
                                                                                    : false
                                                                            }
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                <div className="checkout-checkboxes">
                                                    <label id="custom-checkbox" className="custom-checkbox">
                                                        <input
                                                            name="differentAddress"
                                                            id="differentAddress"
                                                            type="checkbox"
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                        {!!textData &&
                                                            textData["checkout/form"]["information"][
                                                                "checkbox-send-address"
                                                            ]}
                                                    </label>
                                                </div>
                                                <div className="different-address">
                                                    <div className="form-group">
                                                        {values["differentAddress"].length > 0 &&
                                                            values["differentAddress"] !== "0" &&
                                                            Object.entries(values).map(
                                                                ([fieldName, fieldLabel], index) => {
                                                                    if (fieldName === "diff_country") {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <SelectCountry
                                                                                    textData={textData}
                                                                                    countries={countries}
                                                                                    setFieldValue={setFieldValue}
                                                                                    setShippingCountry={
                                                                                        setShippingCountry
                                                                                    }
                                                                                    setLoadingShippingPrices={
                                                                                        setLoadingShippingPrices
                                                                                    }
                                                                                    initialValues={initialValues}
                                                                                    id="diff_country"
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    } else if (
                                                                        fieldName.startsWith("diff") &&
                                                                        fieldName !== "differentAddress"
                                                                    ) {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <div className="formInputField">
                                                                                    <label htmlFor={`${fieldName}`}>
                                                                                        {
                                                                                            textData["checkout/form"][
                                                                                                "form_details"
                                                                                            ][fieldName]
                                                                                        }
                                                                                    </label>
                                                                                    <input
                                                                                        key={index}
                                                                                        type="text"
                                                                                        name={fieldName}
                                                                                        hidden={
                                                                                            values["differentAddress"]
                                                                                                .length === 0
                                                                                        }
                                                                                        value={values[fieldName] ?? ""}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                    />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="checkout-container">
                                                <div className="left-side">
                                                    <div className="checkout-half">
                                                        <h2>
                                                            {!!textData &&
                                                                textData["checkout/form"]["payment"]["title"]}
                                                        </h2>
                                                        <Payment
                                                            textData={!!textData && textData}
                                                            setPaymentChoice={setPaymentChoice}
                                                            setPaymentMethods={setPaymentMethods}
                                                        />
                                                    </div>
                                                    <div className="checkout-half">
                                                        <h2>
                                                            {!!textData &&
                                                                textData["checkout/form"]["delivery"]["title"]}
                                                        </h2>
                                                        <div className="delivery" id="delivery">
                                                            {/* loop through all delivery methods */}
                                                            {deliveryOptions.map((delivery_method, index) => (
                                                                <Fragment key={index}>
                                                                    {deliveryOptions.set_prices &&
                                                                        loadingShippingPrices === false &&
                                                                        delivery_method.price !== null && (
                                                                            <label className="custom-checkbox">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="delivery_method"
                                                                                    value={delivery_method.id}
                                                                                    onChange={(e) => {
                                                                                        deliveryOption.current =
                                                                                            e.target.value;
                                                                                        setDeliveryPrice(
                                                                                            delivery_method.price
                                                                                        );

                                                                                        // Check if pickup option is selected
                                                                                        if (
                                                                                            delivery_method.youropps_name ===
                                                                                            "pickup"
                                                                                        ) {
                                                                                            setShowShippingCosts(false);
                                                                                        } else {
                                                                                            setShowShippingCosts(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                                {delivery_method.name[language]}{" "}
                                                                                <span className="price">
                                                                                    {new Intl.NumberFormat("en-NL", {
                                                                                        style: "currency",
                                                                                        currency: "EUR",
                                                                                    }).format(delivery_method.price)}
                                                                                </span>
                                                                            </label>
                                                                        )}
                                                                </Fragment>
                                                            ))}
                                                            {(deliveryOptions.length === 0 || loadingShippingPrices) &&
                                                                deliveryPriceError === false &&
                                                                document.getElementsByName("country") !== null &&
                                                                document.getElementsByName("country").length > 0 &&
                                                                document.getElementsByName("country")[0].value !==
                                                                    "" && (
                                                                    <>
                                                                        <div className="generic-spinner">
                                                                            <span className="spinner"></span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            {deliveryPriceError === true && (
                                                                <>
                                                                    <p>
                                                                        {!!textData &&
                                                                            textData["checkout/form"][
                                                                                "could_not_fetch_prices"
                                                                            ]}
                                                                    </p>
                                                                </>
                                                            )}
                                                            {document.getElementsByName("country") !== null &&
                                                                document.getElementsByName("country").length > 0 &&
                                                                document.getElementsByName("country")[0].value ===
                                                                    "" && (
                                                                    <>
                                                                        <p>
                                                                            {
                                                                                textData["checkout/form"][
                                                                                    "select_a_country"
                                                                                ]
                                                                            }
                                                                        </p>
                                                                    </>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkout-half">
                                                    <h2>
                                                        {!!textData && textData["checkout/form"]["attachment"]["title"]}
                                                    </h2>
                                                    <div className="attachment">
                                                        <p>
                                                            {!!textData &&
                                                                textData["checkout/form"]["upload_section"][
                                                                    "upload-subtitle"
                                                                ]}
                                                        </p>
                                                        <Upload
                                                            type="attachment"
                                                            textData={!!textData && textData["checkout/form"]}
                                                            noContainer={true}
                                                            showUploadedFiles={true}
                                                            setUploadedAttachments={setUploadedAttachments}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="disclaimer">
                                                <p>
                                                    {!!textData &&
                                                        textData["checkout/form"]["disclaimer"]["personal-data"]}
                                                </p>
                                                <label className="custom-checkbox">
                                                    <input
                                                        name="terms_and_conditions_read"
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        value="0"
                                                    />
                                                    <span className="checkmark"></span>
                                                    <a
                                                        id="terms_and_conditions_read"
                                                        target="_blank"
                                                        rel="norefferer"
                                                        href={getGeneralSetting("terms_and_conditions")}
                                                    >
                                                        {!!textData &&
                                                            textData["checkout/form"]["disclaimer"]["terms-checkbox"]}
                                                    </a>
                                                </label>
                                                <label className="custom-checkbox">
                                                    <input
                                                        name="checklist_read"
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        value="0"
                                                    />
                                                    <span className="checkmark"></span>
                                                    <a
                                                        id="checklist_read"
                                                        target="_blank"
                                                        rel="norefferer"
                                                        href={getGeneralSetting("3d_print_checklist")}
                                                    >
                                                        {!!textData &&
                                                            textData["checkout/form"]["disclaimer"]["3d-checkbox"]}
                                                    </a>
                                                </label>
                                                {hasSubmittedForm === false && (
                                                    <>
                                                        <button
                                                            id="place_order"
                                                            onClick={() => {
                                                                setHasSubmittedForm(true);
                                                                handleSubmit();
                                                            }}
                                                        >
                                                            <div className="shoppingCartIcon">
                                                                <FontAwesomeIcon icon={faCartShopping} />
                                                            </div>
                                                            <span>
                                                                {!!textData && textData["checkout/form"]["payment_btn"]}
                                                            </span>
                                                        </button>
                                                    </>
                                                )}
                                                {hasSubmittedForm === true && (
                                                    <>
                                                        <div className="disclaimer">
                                                            <div className="generic-spinner">
                                                                <span className="spinner"></span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={responsiveClass + "header"}>
                            <NavigationMenu
                                responsive={isTabletOrMobile}
                                authenticated={props.authenticated}
                                navigateToPageName={props.navigateToPageName}
                                textData={!!textData && textData}
                            />
                        </div>
                        <div className={responsiveClass + "cart-container"}>
                            <h1>{!!textData && textData["checkout/form"]["title"]}</h1>
                            <Cart
                                textData={textData}
                                mandatoryProductOptions={props.mandatoryProductOptions}
                                showShippingCosts={showShippingCosts}
                                modelsData={quotationApiData}
                                navigateToPageName={props.navigateToPageName}
                                setShippingInfo={setShippingInfo}
                                shippingCountry={shippingCountry}
                                setDiscounts={setDiscounts}
                            />
                        </div>
                        <div className={responsiveClass + "checkout-container"}>
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                initialValues={initialValues}
                                validate={(values) => {
                                    return handleFormValidation(values);
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setHasSubmittedForm(true);
                                    setTimeout(() => {
                                        handleFormSubmit(values);
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit} id="checkout-form">
                                        {errors.length > 0 && stateErrors.length > 0 && handleValidationErrors(errors)}
                                        <div className="checkout">
                                            <h2>{!!textData && textData["checkout/form"]["information"]["title"]}</h2>
                                            <div className="details">
                                                <div className="form-group">
                                                    {Object.entries(values).map(([fieldName, fieldLabel], index) => {
                                                        if (
                                                            fieldName === "terms_and_conditions_read" ||
                                                            fieldName === "checklist_read"
                                                        ) {
                                                            return "";
                                                        }

                                                        if (fieldName === "country") {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <SelectCountry
                                                                        textData={textData}
                                                                        countries={countries}
                                                                        setFieldValue={setFieldValue}
                                                                        setShippingCountry={setShippingCountry}
                                                                        setLoadingShippingPrices={
                                                                            setLoadingShippingPrices
                                                                        }
                                                                        initialValues={initialValues}
                                                                        id="country"
                                                                    />
                                                                </React.Fragment>
                                                            );
                                                        } else if (!fieldName.startsWith("diff")) {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="formInputField">
                                                                        <label htmlFor={`${fieldName}`}>
                                                                            {
                                                                                textData["checkout/form"][
                                                                                    "form_details"
                                                                                ][fieldName]
                                                                            }
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id={`${fieldName}`}
                                                                            name={`${fieldName}`}
                                                                            value={values[fieldName] ?? ""}
                                                                            onChange={handleChange}
                                                                            readOnly={
                                                                                fieldName === "company"
                                                                                    ? "readonly"
                                                                                    : false
                                                                            }
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                <div className="checkout-checkboxes">
                                                    <label id="custom-checkbox" className="custom-checkbox">
                                                        <input
                                                            name="differentAddress"
                                                            id="differentAddress"
                                                            type="checkbox"
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                        {!!textData &&
                                                            textData["checkout/form"]["information"][
                                                                "checkbox-send-address"
                                                            ]}
                                                    </label>
                                                </div>
                                                <div className="different-address">
                                                    <div className="form-group">
                                                        {values["differentAddress"].length > 0 &&
                                                            values["differentAddress"] !== "0" &&
                                                            Object.entries(values).map(
                                                                ([fieldName, fieldLabel], index) => {
                                                                    if (fieldName === "diff_country") {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <SelectCountry
                                                                                    textData={textData}
                                                                                    countries={countries}
                                                                                    setFieldValue={setFieldValue}
                                                                                    setShippingCountry={
                                                                                        setShippingCountry
                                                                                    }
                                                                                    setLoadingShippingPrices={
                                                                                        setLoadingShippingPrices
                                                                                    }
                                                                                    initialValues={initialValues}
                                                                                    id="diff_country"
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    } else if (
                                                                        fieldName.startsWith("diff") &&
                                                                        fieldName !== "differentAddress"
                                                                    ) {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <div className="formInputField">
                                                                                    <label htmlFor={`${fieldName}`}>
                                                                                        {
                                                                                            textData["checkout/form"][
                                                                                                "form_details"
                                                                                            ][fieldName]
                                                                                        }
                                                                                    </label>
                                                                                    <input
                                                                                        key={index}
                                                                                        type="text"
                                                                                        name={fieldName}
                                                                                        hidden={
                                                                                            values["differentAddress"]
                                                                                                .length === 0
                                                                                        }
                                                                                        value={values[fieldName] ?? ""}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                    />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="checkout-container">
                                                <div className="payment-container">
                                                    <div>
                                                        <h2>
                                                            {!!textData &&
                                                                textData["checkout/form"]["payment"]["title"]}
                                                        </h2>
                                                        <Payment
                                                            textData={!!textData && textData}
                                                            setPaymentChoice={setPaymentChoice}
                                                            setPaymentMethods={setPaymentMethods}
                                                        />
                                                    </div>
                                                    <div>
                                                        <h2>
                                                            {!!textData &&
                                                                textData["checkout/form"]["delivery"]["title"]}
                                                        </h2>
                                                        <div className="delivery" id="delivery">
                                                            {/* loop through all delivery methods */}
                                                            {deliveryOptions.map((delivery_method, index) => (
                                                                <Fragment key={index}>
                                                                    {deliveryOptions.set_prices &&
                                                                        loadingShippingPrices === false &&
                                                                        delivery_method.price !== null && (
                                                                            <label className="custom-checkbox">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="delivery_method"
                                                                                    value={delivery_method.id}
                                                                                    onChange={(e) => {
                                                                                        deliveryOption.current =
                                                                                            e.target.value;
                                                                                        // Check if pickup option is selected
                                                                                        if (
                                                                                            delivery_method.youropps_name ===
                                                                                            "pickup"
                                                                                        ) {
                                                                                            setShowShippingCosts(false);
                                                                                        } else {
                                                                                            setShowShippingCosts(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                                {delivery_method.name[language]}{" "}
                                                                                <span className="price">
                                                                                    {new Intl.NumberFormat("en-NL", {
                                                                                        style: "currency",
                                                                                        currency: "EUR",
                                                                                    }).format(delivery_method.price)}
                                                                                </span>
                                                                            </label>
                                                                        )}
                                                                </Fragment>
                                                            ))}
                                                            {(deliveryOptions.length === 0 || loadingShippingPrices) &&
                                                                deliveryPriceError === false && (
                                                                    <>
                                                                        <div className="generic-spinner">
                                                                            <span className="spinner"></span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            {deliveryPriceError === true && (
                                                                <>
                                                                    <p>
                                                                        {!!textData &&
                                                                            textData["checkout/form"][
                                                                                "could_not_fetch_prices"
                                                                            ]}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h2>
                                                        {!!textData && textData["checkout/form"]["attachment"]["title"]}
                                                    </h2>
                                                    <div className="attachment">
                                                        <p>
                                                            {!!textData &&
                                                                textData["checkout/form"]["upload_section"][
                                                                    "upload-subtitle"
                                                                ]}
                                                        </p>
                                                        <Upload
                                                            type="attachment"
                                                            textData={!!textData && textData["checkout/form"]}
                                                            noContainer={true}
                                                            showUploadedFiles={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="disclaimer">
                                                <p>
                                                    {!!textData &&
                                                        textData["checkout/form"]["disclaimer"]["personal-data"]}
                                                </p>
                                                <label className="custom-checkbox">
                                                    <input
                                                        name="terms_and_conditions_read"
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        value="0"
                                                    />
                                                    <span className="checkmark"></span>
                                                    <a
                                                        id="terms_and_conditions_read"
                                                        target="_blank"
                                                        rel="norefferer"
                                                        href={getGeneralSetting("terms_and_conditions")}
                                                    >
                                                        {!!textData &&
                                                            textData["checkout/form"]["disclaimer"]["terms-checkbox"]}
                                                    </a>
                                                </label>
                                                <label className="custom-checkbox">
                                                    <input
                                                        name="checklist_read"
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        value="0"
                                                    />
                                                    <span className="checkmark"></span>
                                                    <a
                                                        id="checklist_read"
                                                        target="_blank"
                                                        rel="norefferer"
                                                        href={getGeneralSetting("3d_print_checklist")}
                                                    >
                                                        {!!textData &&
                                                            textData["checkout/form"]["disclaimer"]["3d-checkbox"]}
                                                    </a>
                                                </label>
                                                {hasSubmittedForm === false && (
                                                    <>
                                                        <button
                                                            id="place_order"
                                                            onClick={() => {
                                                                setHasSubmittedForm(true);
                                                                handleSubmit();
                                                            }}
                                                        >
                                                            <div className="shoppingCartIcon">
                                                                <FontAwesomeIcon icon={faCartShopping} />
                                                            </div>
                                                            <span>
                                                                {!!textData && textData["checkout/form"]["payment_btn"]}
                                                            </span>
                                                        </button>
                                                    </>
                                                )}
                                                {hasSubmittedForm === true && (
                                                    <>
                                                        <div className="disclaimer">
                                                            <div className="generic-spinner">
                                                                <span className="spinner"></span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </>
                )}
            </div>
        )
    );
}
